export const EN_US = 'en-US';
export const ES_ES = 'es-ES';
export const FR_CA = 'fr-CA';

/*forms */
export const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]){1,61})+$/;
export const SPECIAL_CHARACTERS_REGEX = /^[^:/.<>"\\|?*^]*$/;
export const SPECIAL_CHARACTERS_TXT = '/ . : < > " \\ | ? * ^';
export const KEYPRESS_TIMEOUT = 500;
export const UNALLOWED_FILE_NAMES = [
  'AUX',
  'CLOCK$',
  'COM1',
  'COM2',
  'COM3',
  'COM4',
  'COM5',
  'COM6',
  'COM7',
  'COM8',
  'COM9',
  'CON',
  'LPT1',
  'LPT2',
  'LPT3',
  'LPT4',
  'LPT5',
  'LPT6',
  'LPT7',
  'LPT8',
  'LPT9',
  'NUL',
  'PRN'
];

/* login */
export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';

/* assets */
export const DEFAULT_PAGE_COUNT = '0';
export const DEFAULT_RESOLUTION = '0 x 0';
export const DEFAULT_CONTENT_CREATOR = '-';
export const DIMENSION_SEPARATOR = ' x ';

/* SnackBar*/
export const ALERT_AUTO_HIDE_DURATION = 10000; // in milliseconds

/* SideBar admin*/
export const SIDEBAR_REM_WIDTH_FULL = 17;
export const SIDEBAR_REM_WIDTH_SMALL = 4;

/* Debounce*/
export const DEBOUNCE_TIME = 300;
export const DEFAULT_DEBOUNCE_TIMEOUT = 500;

/* Pills in rows*/
export const USERS_PILLS_MAX_LENGTH = 2;

/* Timer to be loggedout*/
export const PROMPT_BEFORE_IDLE = 20_000; // 20 s
export const DEFAULT_IDLE_TIMER = 60_000 * 30; // 60s * 30 = 30mins

/* DevBar*/
export const DEFAULT_MOBILE_BREAKPOINT = 768;

/* Masonry Cards */
export const LIBRARY_ASSETS_VIEW_ROW_HEIGHT = '6.625rem';

/* Download */
export const DOWNLOAD_INTERVAL = 1000;
export const SIZE_DOWNLOAD_MESSAGE_LIMIT = 1000000; // in bytes

/* Upload */
export const PROGRESS_BAR_TRANSITION_DURATION = '800ms';
export const BYTES_TO_MEGABYTES_DIVIDER = 1000000; // ex: 6 000 000 bytes / BYTES_TO_MEGABYTES_DIVIDER = 6 megabytes

/* Admin Autocomplete */
export const DEFAULT_MAX_SELECTED_ITEMS_SHOWN = 5;

//@TODO: remove this once all autocompletes have been refactored
/* Custom Autocomplete*/
export const LAUNCH_SEARCH_CHARACTERS_MIN = 1;

/* User languages */
export const LANGUAGE_LOCALSTORAGE_KEY = 'userLanguage';

/* Brands */
export const BRAND_IMAGES_LOCALSTORAGE_KEY = 'brandImages';

/* Theme */
export const COLOR_MODE_LOCALSTORAGE_KEY = 'mui-mode';
export const SELECTED_COLOR_LOCALSTORAGE_KEY = 'asmbl-color';
export const DEFAULT_COLOR_MODE = 'light';
export const DEFAULT_COLOR = 'default';

/* AutoSuggestions - Search */
export const SEARCH_SUGGESTIONS_MIN_CHARACTERS = 3;
export const SEARCH_MAX_CHARACTERS = 500;
export const SEARCH_MAX_CHIPS_MOBILE = 2;

export const SEARCH_TUTORIAL_SHOWED_LOCALSTORAGE_KEY = 'searchTutorialShowed';

/* V1 library */
//@TODO: remove this once the home v1 is removed
export const VIEWER_LIBRARY_SHOWCASE_ASSET_COUNT = 8;
export const CONTRIBUTOR_LIBRARY_SHOWCASE_ASSET_COUNT = 4;

/* HEADER */
export const HEADER_HEIGHT_DESKTOP = '17.125rem';

/* Advanced filters */
export const ADVANCED_FILTERS_WIDTH = '300px';

/* Swiper */
export const SWIPER_THRESHOLD = 10;

export const CONTENT_TAGS = 'Content Tags';
