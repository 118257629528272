import { TFunction } from 'react-i18next';
import { AnySchema, array, date, object, string } from 'yup';
import {
  IMetadata,
  AssetGroupsMetadataList,
  MetaDataFieldEnum
} from 'src/app/api/controllers/metadata';
import {
  AssetGroups,
  IAssetTypes,
  IUploadAsset
} from 'src/app/api/models/assets';
import {
  SPECIAL_CHARACTERS_REGEX,
  SPECIAL_CHARACTERS_TXT,
  UNALLOWED_FILE_NAMES
} from 'src/app/utilities/constants';
import {
  getSchemaErrors,
  getSchemaPathErrors,
  IErrors
} from 'src/app/utilities/yup';
import { getAssetGroupNameFromExtension } from 'src/app/utilities/assets';
import { NestedKeyOf } from 'src/app/utilities/utilityTypes';

export const getAssetValidationSchema = (
  asset: IUploadAsset,
  assetMetadatas: IMetadata[],
  t: TFunction<'translation', undefined>
) => {
  const metadataValidationSchema: { [key: string]: AnySchema } = {};
  assetMetadatas.forEach((metadata) => {
    if (metadata.required) {
      switch (metadata.fieldType) {
        case MetaDataFieldEnum.MultiLineTextbox:
        case MetaDataFieldEnum.SingleLineTextbox:
        case MetaDataFieldEnum.RadioButton:
        case MetaDataFieldEnum.SingleValueDropdown:
          metadataValidationSchema[metadata.id] = string().required(
            t('general__field_required')
          );
          break;
        case MetaDataFieldEnum.MultiValueDropdown:
        case MetaDataFieldEnum.Checkbox:
        case MetaDataFieldEnum.CustomKeywords:
          metadataValidationSchema[metadata.id] = array()
            .of(string())
            .required(t('general__field_required'))
            .min(1, t('general__field_required'));
          break;
        case MetaDataFieldEnum.Date:
          metadataValidationSchema[metadata.id] = date()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .required(t('general__field_required'));
          break;
        default:
      }
    }
  });

  return object({
    name: string()
      .required(t('general__field_required'))
      .max(255, t('upload__asset_name_length'))
      .matches(
        SPECIAL_CHARACTERS_REGEX,
        t('upload__asset_name_excludes') + ' : ' + SPECIAL_CHARACTERS_TXT
      )
      .notOneOf(
        UNALLOWED_FILE_NAMES,
        t('upload__asset_cannot_be') + ' : ' + UNALLOWED_FILE_NAMES.join(', ')
      )
      .test(
        'is-not-duplicate',
        t('upload__duplicate_name_error'),
        () => !asset.isDuplicate
      ),
    metadata: object().shape(metadataValidationSchema),
    catalogId: string().required(t('general__field_required'))
  });
};

const getMetadataOfAsset = (
  metadata: AssetGroupsMetadataList,
  assetType: AssetGroups
) => {
  if (!metadata[assetType] && metadata['any']) {
    return [...metadata['any']];
  } else if (metadata[assetType] && !metadata['any']) {
    return [...metadata[assetType]];
  } else {
    return [...metadata['any'], ...metadata[assetType]];
  }
};

export const validateAssets = (
  assets: IUploadAsset[],
  assetTypes: IAssetTypes,
  metadata: AssetGroupsMetadataList,
  t: TFunction<'translation', undefined>
): Promise<IErrors> => {
  return new Promise((resolve) => {
    const formErrors: IErrors = {};
    assets.forEach((asset) => {
      const assetType = getAssetGroupNameFromExtension(
        assetTypes,
        asset.extension
      );

      if (metadata && metadata['any']) {
        const metadataOfAsset = getMetadataOfAsset(metadata, assetType);

        if (metadataOfAsset) {
          const fieldErrors = getSchemaErrors(
            getAssetValidationSchema(asset, metadataOfAsset, t),
            asset
          );

          Object.keys(fieldErrors).map((key) => {
            const newKey = `${asset.id}.${key}`;
            formErrors[newKey] = fieldErrors[key];
          });
        }
      }
    });

    resolve(formErrors);
  });
};

export const validatePropertyInAssets = (
  assetTypes: IAssetTypes,
  metadata: AssetGroupsMetadataList,
  t: TFunction<'translation', undefined>,
  property: NestedKeyOf<IUploadAsset>,
  ...assets: IUploadAsset[]
): IErrors => {
  const propertyErrors: IErrors = {};
  assets.forEach((asset) => {
    const assetType = getAssetGroupNameFromExtension(
      assetTypes,
      asset.extension
    );

    if (metadata && metadata['any'] && metadata[assetType]) {
      const metadataOfAsset = [...metadata['any'], ...metadata[assetType]];

      if (metadataOfAsset) {
        const fieldErrors = getSchemaPathErrors(
          property,
          getAssetValidationSchema(asset, metadataOfAsset, t),
          asset
        );
        Object.keys(fieldErrors).map((key) => {
          const newKey = `${asset.id}.${key}`;
          propertyErrors[newKey] = fieldErrors[key];
        });
      }
    }
  });
  return propertyErrors;
};
